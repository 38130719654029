@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.public-header {
  background-image: url('../public/images/background.jpg');
  background-size: cover;
  background-position: center right;
}
